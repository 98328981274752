import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';
import iblLogo from '../../assets/images/ibl-logo-animated.gif';
import checkImg from '../../assets/images/check-1_1check-1.png';
import cancelImg from '../../assets/images/close-1_1close-1.png';


import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  defaultSPALogoData,
  getProviderImgUrl, googleTrialAPI,
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick, handleTextHelper,
  removeSpaceFromText,
  resetLoginForm, retrieveIDFromProviderUrl, slugify, stringsAreSimilar,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';
import api from '../../utils/api';
import { AuthContext } from '../../utils/context';
import FreeTrial from '../../components/FreeTrial/FreeTrial';
import { IBLSpinLoader, jwtIsValid } from '@iblai/ibl-web-react-common';
import MfeContextUI from '../../components/MfeContextUI/MfeContextUI';
import { useDebounce } from '../../hooks/useDebounce';
import ModalLoader from '../../components/ModalLoader/ModalLoader';
import Footer from '../../components/Footer/Footer';

const LOGO_DATA = defaultSPALogoData;
const CreateOrganization = () => {

  const FREE_TRIAL_ENABLED = process.env.REACT_APP_IBL_ENABLE_FREE_TRIAL === 'true';
  const FREE_TRIAL_QUERY_PARAM = process.env.REACT_APP_IBL_FREE_TRIAL_PRODUCT_GET_QUERY_PARAM_NAME;
  const GOOGLE_QUERY_PARAM = process.env.REACT_APP_IBL_FREE_TRIAL_PRODUCT_GET_GOOGLE_SIGNUP_QUERY_PARAM_NAME;
  const STRIPE_CHECKOUT_ID_QUERY_PARAM = process.env.REACT_APP_IBL_FREE_TRIAL_PRODUCT_GET_STRIPE_CHECKOUT_ID_QUERY_PARAM_NAME;
  const AWS_CHECKOUT_ID_QUERY_PARAM = process.env.REACT_APP_IBL_FREE_TRIAL_PRODUCT_GET_AWS_QUERY_PARAM_NAME;
  const DEFAULT_TIME_OUT = 5000
  const emailExample = process.env.REACT_APP_IBL_EMAIL_EXAMPLE

  const [passwordType, setPasswordType] = useState('password');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [enforceLogin, setEnforceLogin] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const authData = useContext(AuthContext);
  const location = useLocation();
  const [notifications, notify, onClose] = useNotification();

  const [organisationName, setOrganisationName] = useState('');
  const [checkingOrganisationName, setCheckingOrganisationName] = useState(false);
  const [organisationValidityStatus, setOrganisationValidityStatus] = useState('checking');
  const [organizationErrorMsg, setOrganizationErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalDisplayText, setModalDisplayText] = useState('');
  const [isStripeCheckoutFlow, setIsStripeCheckoutFlow] = useState(false);
  const [flowType, setFlowType] = useState('');

  useDebounce(() => {
    handlePasswordEyeClick({
      setPasswordType,
      isPasswordHidden,
      setIsPasswordHidden,
    });
  }, 300, [isPasswordHidden]);

  const closeTab = () => {
    window.close();
  };

  const handleTenantNameCheckAPI = async (data, successCallback, errorCallback) => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/service/tenant/validation/`
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((resp)=>{
      successCallback({
        status_code:resp?.status
      })
    }).catch(err=>{
      errorCallback(err)
    })
  }

  const gcpTokenValidator = async (token, successCallback, errorCallback) => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}/api/providers/gcp/validate-signup-token/`
    const defaultErrMsg = "An error occurred. Please try again. You'll be redirected shortly..."
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        token
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp)=> {
      resp.json().then((data)=>{
        if(!data?.success){
          throw new Error("Your token seems invalid. You'll be redirected back shortly...")
        }
        successCallback(data?.redirect_to)
      }).catch(err=>{
        //successCallback("")
        errorCallback(err?.message || defaultErrMsg)
      })
    }).catch(err=>{
      //successCallback("")
      errorCallback(err?.message || defaultErrMsg)
    })
  }

  const handleOrganizationNameCheck = () => {
    if (String(organisationName) !== '' && !String(organisationName).match(/^[a-zA-Z0-9]+$/)) {
      setOrganizationErrorMsg(`Must be alphanumeric only`);
      setOrganisationValidityStatus('taken');
      return;
    }
    if (organisationName && String(organisationName).length > 2) {
      setCheckingOrganisationName(true);
      handleTenantNameCheckAPI({
        key: slugify(organisationName),
      }, handleOrganizationNameCheckSuccessCallback, () => {
        setOrganisationValidityStatus('checking');
        setCheckingOrganisationName(false);
      });
    } else {
      setOrganisationValidityStatus('checking');
      setCheckingOrganisationName(false);
    }
  };

  const handleOrganizationNameCheckSuccessCallback = (data) => {
    if (data?.status_code === 404) {
      //ORG NAME IS AVAILABLE
      setOrganisationValidityStatus('available');
    } else if (data?.status_code === 200) {
      //ORG NAME IS TAKEN
      setOrganizationErrorMsg(`${organisationName} is already taken`);
      setOrganisationValidityStatus('taken');
    }
    setCheckingOrganisationName(false);
  };

  const handleResetForm = () => {
    resetLoginForm();
  };

  const handleStripeFlowCreateOrganizationSubmit = async ({
    key,
    name,
    stripe_checkout_id,
    notify,
  }) => {
    handleTenantCreationAPI({
      key,
      name,
      stripe_checkout_id
    }, ()=>{
      handleCreateOrganizationSuccessCallback(notify)
    }, (err)=>{
      handleCreateOrganizationErrorCallback(err)
    })
  }

  const handleAWSFlowCreateOrganizationSubmit = async (
    {
      orgName,
      username,
      email,
      password,
      notify,
    },
  ) => {

    handleTenantCreationAPI(
      {
        "x-amzn-marketplace-token": new URLSearchParams(window.location.search).get(AWS_CHECKOUT_ID_QUERY_PARAM),
        username,
        email,
        password,
        firstname: username,
        lastname: '',
        name: orgName,
        key: orgName,
      }, ()=>{
        handleCreateOrganizationSuccessCallback(notify)
      }, (errMsg=null)=>{
        handleCreateOrganizationErrorCallback(errMsg)
      }, false,
      "/api/providers/aws/launch-tenant/"
    )
  };

  const handleTenantCreationAPI = (data, successCallback, errorCallback, needAuth=true, endpointSubPath = "/api/service/launch/tenant/") => {
    const url = `${process.env.REACT_APP_IBL_DM_URL}${endpointSubPath} `
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        ...(needAuth ? {
          Authorization: "Token " + localStorage.getItem("axd_token"),
        } : {})
      }
    }).then((resp)=>{
      resp.json().then((data)=>{
        if(data?.success){
          successCallback(data)
        }else{
          errorCallback(data?.message)
        }
      })
    }).catch(err=>{
      errorCallback(err?.message)
    })
  }

  const handleGCPFlowCreateOrganizationSubmit = async (
    {
      orgName,
      username,
      email,
      password,
      notify,
    },
  ) => {
    const searchParam = new URLSearchParams(window.location.search)
    handleTenantCreationAPI(
      {
        x_gcp_marketplace_token: searchParam.get(GOOGLE_QUERY_PARAM),
        product_id:searchParam.get("product-id"),
        username,
        email,
        password,
        firstname: username,
        lastname: '',
        name: orgName,
        key: orgName,
      }, ()=>{
        handleCreateOrganizationSuccessCallback(notify)
      }, (errMsg=null)=>{
        handleCreateOrganizationErrorCallback(errMsg)
      }, false,
      "/api/providers/gcp/launch-tenant/"
    )
  };

  const handleCreateOrganizationErrorCallback = (errMsg) => {
    if(String(errMsg).includes('expired_token')){
      setModalDisplayText("Your token has expired. Please try again. Closing this tab in a moment...");
      setShowModal(true);
      setTimeout(() => {
        closeTab()
      }, DEFAULT_TIME_OUT);
    }else{
      handleResetForm();
      notify('Organization Creation Failed', errMsg || 'An error occurred', 'error');
    }
  };

  const handleCreateOrganizationSuccessCallback = (notify) => {
    const _redirectTo = localStorage.getItem('redirectTo');
    localStorage.removeItem(FREE_TRIAL_QUERY_PARAM);
    notify('Organization Created Successfully', `Organization created successfully. You'll be redirected to the login page in a moment...`, 'success');
    setTimeout(() => {
      const url = new URL(window.location.href);
      window.location.href = `${url.origin}/login?enforce-login=1${!!_redirectTo ? `&redirect-to=${_redirectTo}` : ''}`;
    }, 4000);
  };



  const stripeFlowFormSubmit = () => {
    $(document).on('click', 'body form.auth-form .auth-submit-btn:not(.disabled)', function(e) {
      e.preventDefault();
      let formIsInvalid = false;
      const formParent = $(this).closest('form');
      const actualFormLevel = parseInt(formParent.attr('data-auth-actual-level'));
      const organisationNameField = $('form.auth-form input.organization-name');
      const typedOrganisationName = organisationNameField.val();
      const organisationNameTextError = organisationNameField.closest('.auth-input-group').find('.text-error');
      const submitButtonLabelElement = $(this).find('.auth-submit-btn-label');

      if (!!!removeSpaceFromText(organisationNameField)) {
        //CHECKING IF EMPTY ORGANISATION FIELD
        formIsInvalid = true;
        organisationNameTextError.addClass('invalid');
        organisationNameTextError.text('This field is required');
        organisationNameTextError.removeClass('hidden');
      }
      if (!!formIsInvalid) {
        //DON'T CONTINUE IF FORM INVALID
        return false;
      }
      //HIDE PREVIOUS FORM LEVEL & SHOW NEXT FORM LEVEL LAYOUT
      formParent.find('input').removeClass('invalid');
      formParent.find('.text-error').addClass('hidden');
      submitButtonLabelElement.text('Submitting');
      $(this).addClass('disabled');
      $(this).find('.btn-spinner-block').removeClass('hidden');
      handleStripeFlowCreateOrganizationSubmit({
        key: typedOrganisationName,
        name: typedOrganisationName,
        stripe_checkout_id:localStorage.getItem(STRIPE_CHECKOUT_ID_QUERY_PARAM),
        notify,
      });
      return false;
    });
  }


  const fullFlowFormSubmit = (callbackFn=handleGCPFlowCreateOrganizationSubmit) => {
    $(document).on('click', 'body form.auth-form .auth-submit-btn:not(.disabled)', function(e) {
      e.preventDefault();
      let formIsInvalid = false;
      const formParent = $(this).closest('form');
      const actualFormLevel = parseInt(formParent.attr('data-auth-actual-level'));
      const organisationNameField = $('form.auth-form input.organization-name');
      const typedOrganisationName = organisationNameField.val();
      const organisationNameTextError = organisationNameField.closest('.auth-input-group').find('.text-error');
      const passwordField = $('form.auth-form input.password');
      const typedPassword = passwordField.val();
      const passwordTextError = passwordField.closest('.auth-input-group').find('.text-error');
      const emailField = $('form.auth-form input.email');
      const typedEmail = String(emailField.val()).toLowerCase();
      const emailTextError = emailField.closest('.auth-input-group').find('.text-error');
      const usernameField = $('form.auth-form input.username');
      const typedUsername = String(usernameField.val()).toLowerCase();
      const usernameTextError = usernameField.closest('.auth-input-group').find('.text-error');
      const submitButtonLabelElement = $(this).find('.auth-submit-btn-label');
      if (actualFormLevel === 1) {
        if (!!!removeSpaceFromText(organisationNameField)) {
          //CHECKING IF EMPTY ORGANISATION FIELD
          formIsInvalid = true;
          organisationNameTextError.addClass('invalid');
          organisationNameTextError.text('This field is required');
          organisationNameTextError.removeClass('hidden');
        }
        if (!!formIsInvalid) {
          //DON'T CONTINUE IF FORM INVALID
          return false;
        }
        //HIDE PREVIOUS FORM LEVEL & SHOW NEXT FORM LEVEL LAYOUT
        formParent.find('input').removeClass('invalid');
        formParent.find('.text-error').addClass('hidden');
        $(`form.auth-form .auth-input-group[data-auth-level=1]`).fadeOut('', () => {
          submitButtonLabelElement.text('Get Started');
          $(this).removeClass('visible');
          formParent.attr('data-auth-actual-level', '2');
          $(`form.auth-form .auth-input-group[data-auth-level=2]`).addClass('visible');
        });
      } else {
        if (!!!removeSpaceFromText(typedEmail)) {
          //CHECKING IF EMPTY EMAIL FIELD
          formIsInvalid = true;
          emailField.addClass('invalid');
          emailTextError.text('This field is required');
          emailTextError.removeClass('hidden');
        } else if (!String(typedEmail).includes('@')) {
          //CHECKING IF EMAIL FIELD DOES CONTAIN @
          formIsInvalid = true;
          emailField.addClass('invalid');
          emailTextError.text('Must be a valid email');
          emailTextError.removeClass('hidden');
        }
        if (!!!removeSpaceFromText(typedUsername)) {
          //CHECKING IF EMPTY EMAIL FIELD
          formIsInvalid = true;
          usernameField.addClass('invalid');
          usernameTextError.text('This field is required');
          usernameTextError.removeClass('hidden');
        } else if (String(typedUsername).length < 4 || String(typedUsername).length > 32) {
          //CHECKING IF USERNAME LENGTH IS VALID
          formIsInvalid = true;
          usernameField.addClass('invalid');
          usernameTextError.text('Must be more than 4 and less than 32 characters');
          usernameTextError.removeClass('hidden');
        } else if (!!!String(typedUsername).match(/^[a-zA-Z0-9]+$/)) {
          //CHECKING IF USERNAME IS ALPHANUMERIC
          formIsInvalid = true;
          usernameField.addClass('invalid');
          usernameTextError.text('Must be alphanumeric only');
          usernameTextError.removeClass('hidden');
        }
        if (!!!removeSpaceFromText(typedPassword)) {
          //CHECKING IF EMPTY PASSWORD FIELD
          formIsInvalid = true;
          passwordField.addClass('invalid');
          passwordTextError.text('This field is required');
          passwordTextError.removeClass('hidden');
        }else if (String(typedPassword).length < 8) {
          formIsInvalid = true;
          passwordField.addClass('invalid');
          passwordTextError.text('Must be more than 8 characters');
          passwordTextError.removeClass('hidden');
        }else if(stringsAreSimilar(typedPassword, String(typedEmail).split('@')[0])){
          formIsInvalid = true;
          passwordField.addClass('invalid');
          passwordTextError.text('Password too similar to email address');
          passwordTextError.removeClass('hidden');
        }else if(stringsAreSimilar(typedPassword, typedUsername)){
          formIsInvalid = true;
          passwordField.addClass('invalid');
          passwordTextError.text('Password too similar to username');
          passwordTextError.removeClass('hidden');
        }
        if (!!formIsInvalid) {
          //DON'T CONTINUE IF FORM INVALID
          return false;
        }
        //SUBMISSION START
        formParent.find('input').removeClass('invalid');
        formParent.find('.text-error').addClass('hidden');
        submitButtonLabelElement.text('Submitting');
        $(this).addClass('disabled');
        $(this).find('.btn-spinner-block').removeClass('hidden');
        callbackFn({
          orgName: typedOrganisationName,
          username: typedUsername,
          email: typedEmail,
          password: typedPassword,
          handleResetForm,
          notify,
        });
        return false;
      }
    });
  };

  useDebounce(() => {
    handleFormSubmitTrigger();
    /*if(isStripeCheckoutFlow){
      stripeFlowFormSubmit()
    }else{
      fullFlowFormSubmit(); 
    }*/
    handleInvalidFieldFocus();
    handleNotificationCloseClick();
    handleTextHelper()
  }, 300, []);

  useDebounce(()=>{
    if(flowType==="stripe"){
      stripeFlowFormSubmit()
    }
    if(flowType==="aws"){
      fullFlowFormSubmit(handleAWSFlowCreateOrganizationSubmit);
    }
    if(flowType==="gcp"){
      fullFlowFormSubmit(handleGCPFlowCreateOrganizationSubmit);
    }


  }, 200, [flowType])


  useDebounce(handleOrganizationNameCheck, 500, [organisationName]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const _expiredToken = queryParams.get('expired_token');

    const _googleToken = queryParams.get(GOOGLE_QUERY_PARAM);
    const _redirectTo = queryParams.get('redirect-to');
    const _stripeCheckoutID = queryParams.get(STRIPE_CHECKOUT_ID_QUERY_PARAM);
    const _awsToken = queryParams.get(AWS_CHECKOUT_ID_QUERY_PARAM);
    if(_redirectTo){
      localStorage.setItem('redirectTo', _redirectTo);
    }
    if(_stripeCheckoutID){
      setIsStripeCheckoutFlow(true)
      setFlowType("stripe")
      localStorage.setItem(STRIPE_CHECKOUT_ID_QUERY_PARAM, _stripeCheckoutID);
    }else if(_awsToken){
      setFlowType("aws")
      localStorage.setItem(AWS_CHECKOUT_ID_QUERY_PARAM, _awsToken);
    }else if (FREE_TRIAL_ENABLED && _googleToken) {
      setFlowType("gcp")
      /*setModalDisplayText('Loading necessary dependencies...');
      setShowModal(true);
      gcpTokenValidator(_googleToken, (redirectUrl)=>{
        localStorage.setItem('redirectTo', redirectUrl);
        setShowModal(false);
      }, (errMsg)=>{
        setModalDisplayText(errMsg);
        setTimeout(() => {
          closeTab()
        }, DEFAULT_TIME_OUT);
      })*/
    }
  }, []);
  return (
    <>
      {showModal && <ModalLoader useIBLSpinner={true} displayText={modalDisplayText} />}
      {(
        <div className="w-layout-vflex auth-page-container">
          <div className="w-layout-vflex auth-page-block">
            <img
              src={LOGO_DATA.logo}
              loading="lazy"
              alt=""
              className="auth-logo-img"
            />
            <div className="w-layout-vflex auth-page-main-content">
              <h2 className="auth-page-title smaller">Create an Organization
              </h2>
              <div className="auth-title-desc">An organization is needed to allow you to add users to your account and
                manage your usage.
              </div>
              <div className="auth-form-block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" data-auth-actual-level="1"
                      className="auth-form" data-wf-page-id="65c26a9f6fe0a28f6cf146c0"
                      data-wf-element-id="afe8801e-2b2f-93ee-84f8-31aafd35eb1a">
                  <div data-auth-level="1" className="w-layout-vflex auth-input-group visible">
                    <input
                      onChange={e => {
                        setOrganisationValidityStatus('checking');
                        setOrganisationName(e?.target?.value);
                      }
                      }
                      value={organisationName}
                      className="auth-input organization-name w-input lowercased-layout" maxLength="256" name="organisation-name"
                      data-name="Password Confirm" placeholder="Name of your organization" type="text"
                      id="organization-name" />
                    <div
                      className={`text-error ${!checkingOrganisationName && organisationValidityStatus === 'taken' ? '' : 'hidden'}`}>{organizationErrorMsg}
                    </div>
                    <div className="w-layout-hflex password-eye-block checking-status-block">
                      <div
                        className={`btn-spinner-block dark-spinner-block ${checkingOrganisationName ? '' : 'hidden'} w-embed`}>
                        <div className="lds-ring dark">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <img src={checkImg} loading="lazy" alt=""
                           className={`status-icon success ${!checkingOrganisationName && organisationValidityStatus === 'available' ? '' : 'hidden'}`} /><img
                      src={cancelImg}
                      loading="lazy" alt=""
                      className={`status-icon error ${!checkingOrganisationName && organisationValidityStatus === 'taken' ? '' : 'hidden'} `} />
                    </div>
                    <div className="text-helper">e.g. brooklynuniversity</div>
                  </div>
                  {
                    !isStripeCheckoutFlow && (
                      <>
                        <div id="email" data-auth-level="2" className="w-layout-vflex auth-input-group"><input
                          className="auth-input email w-input lowercased-layout" autoFocus={true} maxLength="256" name="email"
                          data-name="Email"
                          placeholder="Email address" type="email" id="email" />
                          <div className="text-error hidden">Must be a valid email adress</div>
                          <div className="text-helper">e.g. {emailExample}</div>
                        </div>
                        <div data-auth-level="2" className="w-layout-vflex auth-input-group"><input
                          className="auth-input username w-input lowercased-layout" maxLength="256" name="username" data-name="Username"
                          placeholder="Username" type="text" id="username" />
                          <div className="text-error hidden">Only alphanumeric &amp; less than 32 characters</div>
                          <div className="text-helper">e.g. amazingteacher</div>
                        </div>
                        <div data-auth-level="2" className="w-layout-vflex auth-input-group"><input
                          className="auth-input password-field password w-input" maxLength="256" name="password"
                          data-name="Password" placeholder="Password" type={passwordType} id="password" />
                          <div className="text-error hidden">Minimum 8 characters</div>
                          <div className="w-layout-hflex password-eye-block">
                            <img src={!isPasswordHidden ? eyeOff : eye} loading="lazy"
                                 alt=""
                                 className="password-eye-icon " /></div>
                        </div>
                      </>
                    )
                  }

                  <div
                    className={`w-layout-hflex auth-submit-btn ${!!organisationName && !checkingOrganisationName && organisationValidityStatus === 'available' ? '' : 'disabled'}`}>
                    <div className="auth-submit-btn-label">Continue</div>
                    <div className="btn-spinner-block hidden w-embed">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <input type="submit" data-wait="Please wait..." className="submit-button-2 w-button" value="Submit" />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        </div>
      )}
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
    </>
  );
};

export default CreateOrganization;
